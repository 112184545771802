import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import profileImage from "./profile-image.png";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div style={{
      align: "middle",
      height: 200,
      width: 200,
      borderRadius: 500,
      backgroundImage: `url('${profileImage}')`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginBottom: 50
    }}></div>
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi 👋
    </Text>
    <p>{`This is my personal space where I do some writing and some tinkering. I am interested in software products 🧑‍💻, novel user interfaces 💬, sustainability 🌳 and nutrition 🍠`}</p>
    <p>{`At the moment, I am primarily working on Capmo, which I co-founded in 2018.`}</p>
    <p>{`I hold a masters degree in Electrical Engineering and Information Technology from the Technical University of Munich & studied Technology Management at the Center of Digital Technology and Management.`}</p>
    <p>{`Cheers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      